import { useCallback, type ReactNode } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAnalogItems,
  selectBrand,
  selectDepartment,
  selectOrderId,
  selectReadyScan,
  selectUnreadyList,
} from "./state/slice/data";
import { useListItemCloudStatusQuery } from "./state/services/api";
import { AiOutlineLoading } from "react-icons/ai";
import cn from "classnames";
import BaseButton from "@/ui/base/button";
import { setPage, stepForward } from "./state/slice/page";
import { handleUpdates } from "./state/services/updates";
import { useLogin } from "@/hooks/context/login";
import { useWorkCompleted } from "@/hooks";

function CloudReviewWrapper({ children }: { children: ReactNode }) {
  return (
    <section className="cloud-review">
      <header className="cloud-review__header">
        <h1 className="cloud-review__header-text">Cloud ready check</h1>
      </header>
      {children}
    </section>
  );
}

function CloudReviewUnreadyItems({ loadingState }: { loadingState: boolean }) {
  if (loadingState) {
    return null;
  }

  const unready = useSelector(selectUnreadyList);
  console.log("unready items: ", unready);
  if (unready === false || unready.length === 0) {
    return null;
  }

  const items = unready.map((item) => item.item_barcode);

  return (
    <section className="cloud-review__unready-items">
      <div className="cloud-review__unready-items-header">Items not ready:</div>
      <div>{items.join(",")}</div>
    </section>
  );
}

function CloudReviewButton({ loadingState }: { loadingState: boolean }) {
  const [{ usertoken }] = useLogin();
  const orderId = useSelector(selectOrderId);
  const department = useSelector(selectDepartment);
  const items = useSelector(selectAnalogItems);
  const ready = useSelector(selectReadyScan);
  const dispatch = useDispatch();
  const handleFailClick = () => dispatch(stepForward());
  const { completeWork } = useWorkCompleted("DepartmentOrderComplete");

  const handleSuccessClick = useCallback(() => {
    if (!ready) {
      return;
    }

    handleUpdates(usertoken, orderId, department, items).then((res) => {
      if (!res) {
        return;
      }
      completeWork(orderId);
      dispatch(stepForward());
    });
  }, [ready, usertoken, orderId, department, items, completeWork, dispatch]);

  if (loadingState) {
    return null;
  }

  if (!ready) {
    return (
      <BaseButton
        className="cloud-review__btn cloud-review__btn--fail"
        onClick={handleFailClick}
      >
        Order Not Ready
      </BaseButton>
    );
  }

  return (
    <BaseButton
      className="cloud-review__btn cloud-review__btn--success"
      onClick={handleSuccessClick}
    >
      Complete Department
    </BaseButton>
  );
}

function CloudReviewIcon({
  loadingState,
  ready,
  success,
  err,
}: {
  loadingState: boolean;
  ready: boolean;
  success: boolean;
  err: boolean;
}) {
  const indicatorClass = cn("indicator-light", {
    "indicator-light--loading": loadingState,
    "indicator-light--success": success && ready,
    "indicator-light--error": err || !ready,
  });
  return (
    <section className="cloud-review__icon-wrapper">
      <div className={indicatorClass}>
        {loadingState && <AiOutlineLoading className="spinner" />}
        {!loadingState && <div>{ready ? "✓" : "✖"}</div>}
      </div>
    </section>
  );
}

export function SafetyCheckCloudReview() {
  const brand = useSelector(selectBrand);
  const orderId = useSelector(selectOrderId);
  const items = useSelector(selectAnalogItems);
  const ready = useSelector(selectReadyScan);

  const { data, isLoading, isSuccess, isUninitialized, isError } =
    useListItemCloudStatusQuery({
      orderId,
      brand,
      barcodes: items.map((item) => item.item_barcode),
    });

  const loadingState = data === undefined && (isLoading || isUninitialized);
  // const loadingState = true;

  return (
    <CloudReviewWrapper>
      <CloudReviewIcon
        loadingState={loadingState}
        ready={ready}
        success={isSuccess && data !== undefined}
        err={isError}
      />
      <CloudReviewUnreadyItems loadingState={loadingState} />
      <section className="cloud-review__next-btn-wrapper">
        <CloudReviewButton loadingState={loadingState} />
      </section>
    </CloudReviewWrapper>
  );
}
