import { useDispatch, useSelector } from "react-redux";

import {
  selectOrderId,
  selectDepartment,
  selectAnalogItems,
  setActiveScan,
  selectUnscannedItemQty,
  selectErrorState,
  clearError,
  scanItemAsync,
  selectReadyScan,
} from "./state/slice/data";
import { useCallback, useEffect, useState } from "react";
import { KeyActions, useKeyContext } from "@/hooks/context/scan";
import usePager from "@/hooks/use-pager";
import PagerButtons from "@/ui/components/pager-buttons";
import BaseButton from "@/ui/base/button";
import GoButton from "@/ui/components/go-button";
import { setPage, stepForward } from "./state/slice/page";
import { handleUpdates } from "./state/services/updates";
import { useLogin } from "@/hooks/context/login";
import { AppDispatch } from "./state/store";
import { ItemContainer, ItemList } from "./components/items";
import useTimeout from "@/hooks/use-timeout";

const ErrorArea = ({
  error = { errState: false, errMsg: "" },
  onClick = () => {},
  stopOnError = false,
}: {
  error?: {
    errState?: boolean;
    errMsg?: string;
  };
  id: string;
  onClick?: () => void;
  stopOnError?: boolean;
}) => {
  const { errState, errMsg } = error;

  return errState ? (
    <div className="scan-items__error-area">
      <div className="scan-items__error-message">
        Oops! You scanned <strong>{errMsg}</strong>. That item doesn&apos;t
        belong to this order.
      </div>
      <BaseButton onClick={onClick} className="scan-items__error-button">
        {stopOnError ? "TRY AGAIN" : "CLEAR ERROR"}
      </BaseButton>
    </div>
  ) : (
    <></>
  );
};

export function SafetyCheckScanItems() {
  const [advance, setAdvance] = useState(false);
  const subscribe = useKeyContext();
  const orderId = useSelector(selectOrderId);
  const department = useSelector(selectDepartment);
  const items = useSelector(selectAnalogItems);
  const itemError = useSelector(selectErrorState);
  const unscanned = useSelector(selectUnscannedItemQty);

  const dispatch = useDispatch<AppDispatch>();

  const pager = usePager(items, 25);

  useEffect(() => {
    const unsubscribe = subscribe((event) => {
      switch (event.type) {
        case KeyActions.key:
          return dispatch(setActiveScan(event.key));
        case KeyActions.enter:
          return dispatch(scanItemAsync());
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (unscanned === 0 && itemError.status === false && items.length > 0) {
      setAdvance(true);
    }
  }, [unscanned]);

  useTimeout(
    () => {
      dispatch(stepForward());
    },
    advance,
    750,
  );

  const handleErrorFn = () => {
    if (itemError.status) {
      if (!itemError.recoverable) {
        window.location.reload();
        return;
      }
      dispatch(clearError());
    }
  };

  if (items.length === 0) {
    return (
      <ItemContainer department={department}>
        <div className="item-container__empty">
          <div className="item-container__empty-message">
            No items for this department found in order.
          </div>
          <BaseButton
            onClick={() => {
              window.location.reload();
            }}
            className="scan-items__error-button"
          >
            TRY AGAIN
          </BaseButton>
        </div>
      </ItemContainer>
    );
  }

  return (
    <>
      <ItemContainer department={department}>
        <ItemList items={pager.current} />
        <PagerButtons {...pager} displayPageNum={items.length > 25} />
      </ItemContainer>
      <ErrorArea
        id={orderId}
        error={{ errState: itemError.status, errMsg: itemError.msg }}
        onClick={handleErrorFn}
        stopOnError={!itemError.recoverable}
      />
    </>
  );
}
